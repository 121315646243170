<template>
	<div class="saleSummary">
		<div class="item">
			<v-text-field
				v-model="pageData.aftersaleNo"
				label="售后单号"
				hide-details="auto"
				outlined
				:dense="true"
				:height="42"
				disabled
			>
			</v-text-field>
		</div>
		<div class="item">
			<v-text-field
				:value="pageData.creater"
				label="申请人"
				hide-details="auto"
				outlined
				:dense="true"
				:height="42"
				disabled
			>
			</v-text-field>
		</div>
		<div class="item">
			<v-text-field
				:value="pageData.schoolName"
				label="售后校区"
				hide-details="auto"
				outlined
				:dense="true"
				:height="42"
				disabled
			>
			</v-text-field>
		</div>
		<div class="item">
			<v-text-field
				:value="aftersaleType === String(aftersaleTypeEnum.dropout) ? '退费' : aftersaleType === String(aftersaleTypeEnum.upgrade) ? '升班' : aftersaleType === String(aftersaleTypeEnum.transfer) ? '转班' : '转校'"
				label="售后类型"
				hide-details="auto"
				outlined
				:dense="true"
				:height="42"
				disabled
			>
			</v-text-field>
		</div>
		<div class="item">
			<v-text-field
				:value="afterSaleStatusMap[pageData.status]"
				label="售后状态"
				hide-details="auto"
				outlined
				:dense="true"
				:height="42"
				disabled
			>
			</v-text-field>
		</div>
		<div class="item">
			<v-text-field
				:value="pageData.updateTime"
				label="售后单更新"
				hide-details="auto"
				outlined
				:dense="true"
				:height="42"
				disabled
			>
			</v-text-field>
		</div>
	</div>
</template>

<script>
import { afterSaleStatusMap, aftersaleTypeEnum } from '@/views/order/constants'
export default {
	props: {
		pageData: Object,
		aftersaleType:[String, Number]
	},
	data(){
		return {
			afterSaleStatusMap,
			aftersaleTypeEnum,
		}
	}
}
</script>

<style lang="scss" scoped>
.saleSummary{
	display: flex;
	flex-wrap: wrap;
	padding-top: 8px;
	.item{
		width: 282px;
		margin-right: 24px;
		margin-bottom: 21px;
	}
}
</style>