var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "saleSummary" }, [
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("v-text-field", {
          attrs: {
            label: "售后单号",
            "hide-details": "auto",
            outlined: "",
            dense: true,
            height: 42,
            disabled: ""
          },
          model: {
            value: _vm.pageData.aftersaleNo,
            callback: function($$v) {
              _vm.$set(_vm.pageData, "aftersaleNo", $$v)
            },
            expression: "pageData.aftersaleNo"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("v-text-field", {
          attrs: {
            value: _vm.pageData.creater,
            label: "申请人",
            "hide-details": "auto",
            outlined: "",
            dense: true,
            height: 42,
            disabled: ""
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("v-text-field", {
          attrs: {
            value: _vm.pageData.schoolName,
            label: "售后校区",
            "hide-details": "auto",
            outlined: "",
            dense: true,
            height: 42,
            disabled: ""
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("v-text-field", {
          attrs: {
            value:
              _vm.aftersaleType === String(_vm.aftersaleTypeEnum.dropout)
                ? "退费"
                : _vm.aftersaleType === String(_vm.aftersaleTypeEnum.upgrade)
                ? "升班"
                : _vm.aftersaleType === String(_vm.aftersaleTypeEnum.transfer)
                ? "转班"
                : "转校",
            label: "售后类型",
            "hide-details": "auto",
            outlined: "",
            dense: true,
            height: 42,
            disabled: ""
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("v-text-field", {
          attrs: {
            value: _vm.afterSaleStatusMap[_vm.pageData.status],
            label: "售后状态",
            "hide-details": "auto",
            outlined: "",
            dense: true,
            height: 42,
            disabled: ""
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item" },
      [
        _c("v-text-field", {
          attrs: {
            value: _vm.pageData.updateTime,
            label: "售后单更新",
            "hide-details": "auto",
            outlined: "",
            dense: true,
            height: 42,
            disabled: ""
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }